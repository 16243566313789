<template>
  <div class="ask_center">
    <div class="ask_right">
      <ul class="tab flex">
        <li
          v-for="(item, index) in tab"
          :class="{ active: active == index }"
          :key="index"
          @click="tabs(index)"
        >
          {{ item }}
        </li>
      </ul>

      <ul
        class="list list1 loads"
        v-loading="loading"
        element-loading-text="拼命加载中"
        element-loading-spinner="el-icon-loading"
        v-if="active == 0"
      >
        <li class="box boxs relative" v-for="(n, i) in list" :key="i">
          <div class="flext">
            <h3 class="cursorP">
              <router-link
                :to="'/asklib/askDetail/?id=' + n.id"
                target="_blank"
                >{{ n.askTitle }}</router-link
              >
            </h3>
            <p v-if="n.status == 0" class="p3">待审核</p>
            <p v-if="n.status == 1" class="p1">已通过</p>
            <p v-if="n.status == 2" class="p2">未通过</p>
          </div>
          <span class="time">{{ n.createDate }}</span>
          <div class="flext">
            <div class="avatar">
              <img
                :src="n.isAnonymity == 0 ? n.askPhoto : null"
                alt
                class="err_avatar"
              />
              <span>{{ n.isAnonymity == 0 ? n.askName : "匿名提问" }}</span>
            </div>
            <p v-if="n.status == '2'">审核不通过理由：{{ n.auditsMsg }}</p>
          </div>
          <div class="text flex text_4">
            <viewer
              :images="JSON.parse(n.pic1)"
              class="inline_block viewer"
              v-if="JSON.parse(n.pic1)"
            >
              <img
                :src="item"
                alt
                :width="JSON.parse(n.pic1).length > 1 ? 100 : 200"
                v-for="(item, index) in JSON.parse(n.pic1)"
                :key="index"
              />
            </viewer>
            <span>{{ n.content }}</span>
          </div>
          <p>
            <span>
              <i class="el-icon-view"></i>
              {{ n.viewNumber }} 阅读
            </span>
            <span>
              <i class="el-icon-chat-dot-square"></i>
              {{ n.answerNumber }} 回答
            </span>
            <span>
              <i class="el-icon-circle-plus-outline"></i>
              {{ n.collectNumber }} 关注
            </span>
          </p>
        </li>
        <li v-if="active == 0 && status.isHide == 1">
          <status :type="status.type" :des="status.des"></status>
        </li>
      </ul>

      <ul
        class="list list3 list4 loads"
        v-if="active == 1"
        v-loading="loading"
        element-loading-text="拼命加载中"
        element-loading-spinner="el-icon-loading"
      >
        <li class="box" v-for="(n, i) in list3" :key="i">
          <div class="flext">
            <div class="text flex">
              <span class="ques">问：</span>
              <router-link
                :to="'/asklib/askDetail?id=' + n.askId"
                :title="n.askOutDTO.content"
                class="pwidth"
              >
                <p class="line_clamp2 ">{{ n.askOutDTO.content }}</p>
              </router-link>
            </div>
            <p v-if="n.status == 0" class="p3">待审核</p>
            <p v-if="n.status == 1" class="p1">已通过</p>
            <p v-if="n.status == 2" class="p2">未通过-{{ n.auditsMsg }}</p>
          </div>
          <div class="const" v-html="n.content"></div>
          <div class="features flex relative">
            <div></div>
            <div class="time">{{ n.createDate }}</div>
          </div>
        </li>
        <li v-if="active == 1 && status.isHide == 2">
          <status :type="status.type" :des="status.des"></status>
        </li>
      </ul>
      <ul
        class="list list3 loads"
        v-if="active == 2"
        v-loading="loading"
        element-loading-text="拼命加载中"
        element-loading-spinner="el-icon-loading"
      >
        <li class="box" v-for="(n, id) in list2" :key="id">
          <div class="avatar"></div>
          <div class="flext">
            <div class="text flex">
              <span class="ques">我：</span>
              <p class="pwidth">{{ n.content }}</p>
            </div>
              <p v-if="n.status == 0" class="p3">待审核</p>
            <p v-if="n.status == 1" class="p1">已通过</p>
            <p v-if="n.status == 2" class="p2">未通过-{{ n.auditsMsg }}</p>
          </div>
          <div class="const" v-html="n.answerContent"></div>
        </li>
        <li v-if="active == 2 && status.isHide == 3">
          <status :type="status.type" :des="status.des"></status>
        </li>
      </ul>
      <ul
        class="list list3 list4 loads"
        v-if="active == 3"
        v-loading="loading"
        element-loading-text="拼命加载中"
        element-loading-spinner="el-icon-loading"
      >
        <li class="box " v-for="(n, i) in list1" :key="i">
          <div class="text flex">
            <span class="ques">问：</span>
            <router-link
              :to="'/asklib/askDetail?id=' + n.askId"
              :title="n.aswearName"
            >
              <p class="line_clamp2 pwidth" v-html="n.aswearName"></p>
            </router-link>
          </div>
          <div class="const" v-html="n.askName"></div>
          <div class="features col_zan">
            <span class="zan" @click="answerDianzan(n.answerId)">取消点赞</span>
            <span class="time">于 {{ n.createDate }} 赞了该回答</span>
          </div>
        </li>
        <li
          v-if="active == 3 && status.isHide == 4"
          v-loading="loading"
          element-loading-text="拼命加载中"
          element-loading-spinner="el-icon-loading"
        >
          <status :type="status.type" :des="status.des"></status>
        </li>
      </ul>

      <ul
        class="list list1 loads"
        v-if="active == 4"
        v-loading="loading"
        element-loading-text="拼命加载中"
        element-loading-spinner="el-icon-loading"
      >
        <li class="box boxs relative" v-for="(n, i) in list4" :key="i">
          <h3 class="cursorP">
            <router-link
              :to="'/asklib/askDetail/?id=' + n.id"
              target="_blank"
              >{{ n.askTitle }}</router-link
            >
          </h3>
          <span class="time">{{ n.createDate }}</span>
          <div
            class="avatar cursorP"
            @click="goRouter('/asklib/askCenter/', n.memberId, n.isAnonymity)"
          >
            <img
              :src="n.isAnonymity == 0 ? n.askPhoto : null"
              alt
              class="err_avatar"
            />
            <span>{{ n.isAnonymity == 0 ? n.askName : "匿名用户" }}</span>
          </div>
          <div class="text flex text_4">
            <viewer
              :images="JSON.parse(n.pic1)"
              class="inline_block viewer"
              v-if="JSON.parse(n.pic1)"
            >
              <img
                :src="item"
                alt
                :width="JSON.parse(n.pic1).length > 1 ? 100 : 200"
                v-for="(item, index) in JSON.parse(n.pic1)"
                :key="index"
              />
            </viewer>
            <span>{{ n.content }}</span>
          </div>
          <p>
            <span>
              <img src="~/static/ask/read.png" alt />
              {{ n.viewNumber }}阅读
            </span>
            <span>
              <img src="~/static/ask/comments.png" alt />
              {{ n.answerNumber }}回答
            </span>
            <span>
              <img src="~/static/ask/focus.png" alt />
              {{ n.collectNumber }}关注
            </span>
          </p>
        </li>
        <li v-if="active == 4 && status.isHide == 5">
          <status :type="status.type" :des="status.des"></status>
        </li>
      </ul>

      <div class="pages">
        <el-pagination
          background
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          class="work_pagination"
          :current-page.sync="page.pageNum"
          :page-sizes="[5, 10, 15, 20]"
          :page-size="page.pageSize"
          layout="total,prev, pager, next"
          :total="total"
        ></el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "askCenter",
  data() {
    return {
      active: "0",
      loading: false,
      tab: ["我提问的", "我回答的", "我评论的", "我点赞的", "我关注的"],
      list: [],
      list2: [],
      list3: [],
      list1: [],
      list4: [],
      total: 0,
      status: {
        isHide: 0,
        type: "",
        des: "暂无职位数据",
      },
      page: {
        pageNum: 1,
        pageSize: 8,
      },
      image: [],
      isHide: false,
    };
  },
  methods: {
    async queryMyAsk() {
      try {
        this.loading = true;
        let res = await this.$api.queryMyAsk(this.page);
        if (res.data.success) {
          this.list = res.data.data.records;
          this.total = res.data.data.total;
          this.loading = false;
          if (this.list.length <= 0) {
            this.status.isHide = 1;
            this.status.type = "post";
            this.status.des = "暂无我提问的";
          }
        } else {
          this.$message.error(res.data.msg);
          this.status.isHide = 1;
          this.status.type = "post";
          this.status.des = "暂无匹配数据";
          this.loading = false;
        }
      } catch (error) {
        console.log(error);
      }
    },
    async queryMyAskAnswer() {
      try {
        this.loading = true;
        let res = await this.$api.queryMyAskAnswer(this.page);
        if (res.data.success) {
          this.list3 = res.data.data.records;
          this.total = res.data.data.total;
          this.loading = false;
          if (this.list3.length <= 0) {
            this.status.isHide = 2;
            this.status.type = "post";
            this.status.des = "暂无我回答的！";
          }
        } else {
          this.$message.error(res.data.msg);
          this.loading = false;
          this.status.isHide = 2;
          this.status.type = "post";
          this.status.des = "暂无匹配数据";
        }
      } catch (error) {
        console.log(error);
      }
    },
    async queryMyAnswerComment() {
      try {
        this.loading = true;
        let res = await this.$api.queryMyAnswerComment(this.page);
        if (res.data.success) {
          this.list2 = res.data.data.records;
          this.total = res.data.data.total;
          this.loading = false;
          if (this.list2.length <= 0) {
            this.status.isHide = 3;
            this.status.type = "post";
            this.status.des = "暂无我评论的！";
          }
        } else {
          this.$message.error(res.data.msg);
          this.status.isHide = 3;
          this.status.type = "post";
          this.status.des = "暂无匹配数据";
        }
      } catch (error) {
        console.log(error);
      }
    },
    async queryMyDianZan() {
      try {
        this.loading = true;
        let res = await this.$api.queryMyDianZan(this.page);
        if (res.data.success) {
          this.list1 = res.data.data.records;
          this.total = res.data.data.total;
          this.loading = false;
          if (this.list1.length <= 0) {
            this.status.isHide = 4;
            this.status.type = "post";
            this.status.des = "暂无我点赞的！";
          }
        } else {
          this.$message.error(res.data.msg);
          this.status.isHide = 4;
          this.status.type = "post";
          this.status.des = "暂无匹配数据";
        }
      } catch (error) {
        console.log(error);
      }
    },
    async queryMyCyAskGuan() {
      try {
        this.loading = true;
        let res = await this.$api.queryMyCyAskGuan(this.page);
        if (res.data.success) {
          this.list4 = res.data.data.records;
          this.total = res.data.data.total;
          this.loading = false;
          if (this.list4.length <= 0) {
            this.status.isHide = 5;
            this.status.type = "post";
            this.status.des = "暂无我关注的问题！";
          }
        } else {
          this.$message.error(res.data.msg);
          this.status.isHide = 5;
          this.status.type = "post";
        }
      } catch (error) {
        console.log(error);
      }
    },

    tabs(i) {
      this.active = i;
      if (i == 0) {
        this.queryMyAsk();
      }
      if (i == 1) {
        this.queryMyAskAnswer();
      }
      if (i == 2) {
        this.queryMyAnswerComment();
      }
      if (i == 3) {
        this.queryMyDianZan();
      }
      if (i == 4) {
        this.queryMyCyAskGuan();
      }
    },
    //分页
    handleCurrentChange(val) {
      this.page.pageNum = val;
      this.tabs(this.active);
    },
    handleSizeChange(val) {
      this.pageSize = val;
      this.pageNum = 1;
      this.tabs(this.active);
    },
    //点赞回答
    answerDianzan(id) {
      if (localStorage.getItem("userType")) {
        this.$api.answerDianzanApi({ answerId: id }).then((res) => {
          this.queryMyDianZan();
        });
      } else {
        this.isHide = true;
      }
    },
    //点赞评论
    commentDianzan(id, ids, zan) {
      this.$api.commentDianzanApi({ id: ids }).then((res) => {
        if (zan) {
          this.$message.error(res.data.msg);
        } else {
          this.$message.success(res.data.msg);
        }
        this.info(2);
      });
    },
    //路由
    goRouter(url, id, isAnonymity, status) {
      if (status == 1 || status == null) {
        if (isAnonymity == 1) {
          this.$message.warning("无法进入匿名用户主页！");
        } else {
          const { href } = this.$router.resolve({
            path: url,
            query: {
              id: id,
            },
          });
          window.open(href, "_blank");
        }
      } else {
        this.$message.warning("无法查看！");
        return;
      }
    },
    handleClose() {
      this.isHide = false;
    },
  },
  created() {
    this.tabs(0);
  },
};
</script>

<style scoped lang="less">
@import "~assets/css/enterprise/home/ask";
.p1 {
  color: #00924b;
  width: 60px;
}

.p2 {
  color: #f82400;
  width: 60px;
}
.pwidth {
  width: 80%;
}
.p3 {
  color: #666666;
  width: 60px;
}
.avatars {
  margin: 15px 0px;
}
.boxs {
  // background: #f8f8f8 !important;
  border-bottom: 1px solid #e1e1e1 !important;
}

.list {
  margin-top: 10px !important;
}
.text_4 {
  cursor: initial !important;
}
.status {
  width: 83% !important;
  margin-top: 25px;
}
.pages {
  padding-top: 90px;
}
/deep/ .el-loading-mask {
  top: 28px;
  right: 70px;
}
/deep/ .el-loading-spinner {
  top: 5%;
}
</style>
